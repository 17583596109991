<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="data"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :search="search"
          :preloader="fetchingData"
        />
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <div v-if="editItem">
      <EditAdmin
        :trigger="editItem"
        :id="itemId"
        :job="job"
        @createdItem="
          editItem = false;
          getData();
        "
        @closeModal="editItem = false"
      ></EditAdmin>
    </div>

    <v-btn
      fixed
      dark
      fab
      bottom
      right
      color="primary"
      @click="
        job = 'create';
        editItem = true;
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
import EditAdmin from "@/components/csm/admins/EditAdmin.vue";
export default {
  name: "Admins",

  components: {
    PageHeader,
    Subnavigation,
    DataTable,
    EditAdmin,
  },

  data() {
    return {
      pageTitle: "Administratoren",
      pageDescription: "Die Admins der Company",
      title: "",
      search: "",
      fetchingData: false,
      data: [],
      editItem: false,
      itemId: 0, // id des Items, welches geklickt wurde
      itemIndex: 0, // index des Items, welches geklickt wurde
      itemData: null, // Daten des Items, welches geklickt wurde
      job: "update",
      headers: [
        { text: "ID", value: "id" },
        { text: "firstname", value: "firstname" },
        { text: "lastname", value: "lastname" },
        { text: "mail", value: "mail" },
        { text: "type", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      preloader: false,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        `companies/${this.$route.params.id}/admins`
      );
      this.preloader = false;
      this.data = response.data.data;

      /* Fetching Companyname for Headline */
      /* USEING REUSEABLE MIXIN METHOD */
      response = await this.getRequest("companies/" + this.$route.params.id); // await data from mixin
      this.title = response.data.data[0].name;
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;
        this.editItem = true;
      } else if (value.action === "createdItem") {
        this.data.push(value.newPost);
        this.createItem = false;
      } else if (value.action === "deleteItem") {
        this.deletePrompt(value.itemId); // reload
      } else if (value.action === "closeModal") {
        this.createItem = false;
        this.editItem = false;
      } else if (value.action === "itemPatched") {
        this.getData(); // reload
      }
    },
  },

  mixins: [apiRequest],
};
</script>
